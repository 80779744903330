import React from 'react';
import { Form, Select, Row, Col, Input, DatePicker } from 'antd';
import moment from 'moment';
import { DispatchContext } from 'routes/authorized'
import { DateFormatEnum } from 'utils';
import { connect } from 'react-redux';
import { NextButton } from 'utils/theme';
interface Step1Props {
    handleStep1Change: (value: string, type: string) => void;
    showStep: (step: string) => void;
    userName: string
}
interface Step1State {
    date: string
}

class Step1 extends React.Component<Step1Props, Step1State> {
    static context = DispatchContext;

    constructor(props: Step1Props) {
        super(props)
        var date1 = new Date();
        var date2 = new Date(date1);
        date2.setDate(date1.getDate() + 90);
        var t = moment(date2).format(DateFormatEnum.minuteDate);
        this.state = {
            date: t,
        };
    }

    render() {
        return (
            <div className="step1-section">
                <section className="code-box-meta markdown">
                    <div className="code-box-title">
                        <div><span className="step-number">Step1: </span>Input the summary information of quitation</div>
                    </div>
                    <div className="code-box-description">
                        <div style={{padding: '15px'}}>
                            <Form
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}>
                                <Row gutter={48} className="step1-bg">
                                    <Col className="gutter-row" md={6} lg={8}>
                                        <Form.Item label="Ligentia Quote Reference"></Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={12} lg={8}>
                                        <Form.Item label="Valid Till:" style={{ textAlign: 'right'}}>{this.state.date}</Form.Item> 
                                    </Col>
                                    <Col className="gutter-row" md={6} lg={8}> 
                                        <Form.Item label="Quote requested by:" style={{ textAlign: 'right'}}>{this.props.userName}</Form.Item> 
                                    </Col>
                                </Row>
                                <Row gutter={48}>
                                    <Col className="gutter-row" md={6} lg={8} >
                                        <Form.Item label="Request Date">
                                            <div className="step1-input">
                                                <DatePicker
                                                    defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                                                    onChange={(value, valueString) => this.props.handleStep1Change(valueString, 'admRequestDate')}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={6} lg={8}>
                                        <Form.Item label="Brand/Project">
                                            <div className="step1-input">
                                                <Input
                                                    className="border-input"
                                                    placeholder="Please input"
                                                    style={{ textAlign: 'left' }}
                                                    onBlur={(event) => this.props.handleStep1Change(event.target.value, 'brandProject')}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={6} lg={8} >
                                        <Form.Item style={{ textAlign: 'right' }} label="Who will pay import VAT?">
                                            <div className="step1-input">
                                                <Select
                                                    placeholder="Please select"
                                                    style={{ textAlign: 'left', width: '100%' }}
                                                    onChange={(value) => this.props.handleStep1Change(value as string, 'payImportVat')}
                                                >
                                                    <Select.Option value="Importing Client">Importing Client</Select.Option>
                                                    <Select.Option value="Entity">Entity</Select.Option>
                                                </Select>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={48} style={{ height: '48px' }}>
                                    <Col className="gutter-row" md={6} lg={8}  >
                                        <Form.Item label="Cargo Ready Date">
                                            <div className="step1-input">
                                                <DatePicker onChange={(value, valueString) => this.props.handleStep1Change(valueString, 'cargoReadyDate')} />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={6} lg={8} >
                                        <Form.Item label="Insurance Required?">
                                            <div className="step1-input">
                                                <Select
                                                    defaultValue={'No'}
                                                    placeholder="Please select"
                                                    style={{ textAlign: 'left', width: '100%' }}
                                                    onChange={(value) => this.props.handleStep1Change(value, 'insuranceRequired')}
                                                >
                                                    <Select.Option value="Yes">Yes</Select.Option>
                                                    <Select.Option value="No">No</Select.Option>
                                                </Select>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={6} lg={8} >
                                        <Form.Item label="Who should Ligentia invoice?">
                                            <div className="step1-input">
                                                <Select
                                                    placeholder="Please select"
                                                    style={{ textAlign: 'left', width: '100%' }}
                                                    onChange={(value) => this.props.handleStep1Change(value as string, 'ligentiaInvoice')}
                                                >
                                                    <Select.Option value="Malaysia">Malaysia</Select.Option>
                                                    <Select.Option value="Netherlands">Netherlands</Select.Option>
                                                    <Select.Option value="UK">UK</Select.Option>
                                                    <Select.Option value="Hong Kong">Hong Kong</Select.Option>
                                                    <Select.Option value="USA">USA</Select.Option>
                                                    <Select.Option value="Korea">Korea</Select.Option>
                                                    <Select.Option value="Thailand">Thailand</Select.Option>
                                                    <Select.Option value="Singapore">Singapore</Select.Option>
                                                    <Select.Option value="Vietnam">Vietnam</Select.Option>
                                                    <Select.Option value="Australia">Australia</Select.Option>
                                                    <Select.Option value="New Zealand">New Zealand</Select.Option>
                                                    <Select.Option value="Spain">Spain</Select.Option>
                                                    <Select.Option value="Japan">Japan</Select.Option>
                                                    <Select.Option value="Canada">Canada</Select.Option>
                                                </Select>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={48} style={{ height: '48px' }}>
                                    <Col className="gutter-row" md={6} lg={8}  >
                                        <Form.Item label="Ligentia Quote Date">
                                            <div className="step1-input">
                                                <DatePicker
                                                    defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                                                    onChange={(value, valueString) => this.props.handleStep1Change(valueString, 'ligentiaQuoteDate')}
                                                />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={6} lg={8} >
                                        <Form.Item label="Transport Mode">
                                            <div className="step1-input">
                                                <Select
                                                    placeholder="Please select"
                                                    style={{ textAlign: 'left', width: '100%' }}
                                                    defaultValue='SEA'
                                                    onChange={(value) => this.props.handleStep1Change(value, 'transportMode')}
                                                >
                                                    <Select.Option value="SEA">SEA</Select.Option>
                                                </Select>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={6} lg={8} >
                                        <Form.Item label="Select your currency">
                                            <div className="step1-input">
                                                <Select placeholder="Please select"
                                                    defaultValue='USD'
                                                    style={{ textAlign: 'left', width: '100%' }}
                                                    onChange={(value) => this.props.handleStep1Change(value, 'currency')}
                                                >
                                                    <Select.Option value="USD">USD</Select.Option>
                                                    <Select.Option value="EUR">EUR</Select.Option>
                                                    <Select.Option value="GBP">GBP</Select.Option>
                                                    <Select.Option value="HKD">HKD</Select.Option>
                                                    <Select.Option value="CAD">CAD</Select.Option>
                                                </Select>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={48}>
                                    <Col className="gutter-row" md={6} lg={8} ></Col>
                                    <Col className="gutter-row" md={6} lg={8} ></Col>
                                    <Col className="gutter-row" md={6} lg={8} style={{ textAlign: 'right' }}>
                                        <NextButton onClick={() => this.showStep2()}>NEXT</NextButton>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    showStep2() {
        this.props.showStep('step2');
    }
}

export default connect((store: any) => {
    return {
        userName: store.username
    }
})(Step1)