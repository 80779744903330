import { Modal, Form, Select } from "antd";
import React from "react";
import { connect } from "react-redux";
import { AGetGetInfraUserTenantList, APutSwitchTenant } from "services/login";
const { Option } = Select;

interface SwitchClientProps {
    tenantName: string,
    showModel: boolean
    onClose: () => void
}

interface SwitchClientState {
    loading: boolean
    vo?: Api.LoginAccounts.AGetGetInfraUserTenantList.Response
    switchPo: Api.LoginAccounts.APutSwitchTenant.Request
}

class SwitchClient extends React.Component<SwitchClientProps, SwitchClientState> {
    state: SwitchClientState = {
        loading: false,
        switchPo: {
            tenantId: 0
        }
    }

    componentDidMount() {
        this.getTenantSelectList()
    }

    getTenantSelectList = async () => {
        const data = await AGetGetInfraUserTenantList()
        this.setState({
            vo: data
        })
    }

    onChange = (value: string, event: any) => {
        var po = this.state.switchPo;
        po.tenantId = event == undefined ? 0 : parseInt(event.key);
        this.setState({
            switchPo: {...po}
        })
    };

    onOk = async () => {
        const data = await APutSwitchTenant(this.state.switchPo);
        if(data.success){
            window.location.reload();
        }
    }

    render() {
        return (
            <>
                <Modal
                    title="Switch Client"
                    visible={this.props.showModel}
                    onCancel={this.props.onClose}
                    onOk={this.onOk}
                    confirmLoading={this.state.loading}
                >
                    <Form
                        size="large"
                        name="loginForm"
                        labelCol={{ span: 24 }}
                    >
                        <Form.Item name="client" label="Client" rules={[{ required: true }]}>
                            <Select
                                defaultValue={this.props.tenantName}
                                placeholder="Select the client which you want to change"
                                onChange={(value, event) => this.onChange(value, event)}
                                allowClear
                            >
                                {
                                    this.state.vo?.data?.values?.map(u => {
                                        return (<Option key={u.tenantId} value={u.tenantName}>{u.tenantName}</Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
    }
}

export default connect((store: any) => {
    return {
        tenantName: store.tenantName
    }
})(
    SwitchClient
);