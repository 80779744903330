import React from 'react'
import { BrowserRouter, Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux'
import reducer from './reducers/index'
import Login from "./pages/login/index";
import LayoutContainer from "./layout/layout-container-black";

const store = createStore(reducer)

export default class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path='/login' exact={true} component={Login}></Route>
            <Route component={LayoutContainer} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}