import React from 'react';
import { Form, Input, Button, Card, message, FormProps, Typography } from 'antd';
import { APostToken } from 'services/login';
import { RouteComponentProps } from 'react-router-dom';
import { GetUrlParams } from 'utils';
import "./style.less"
import logo from 'assets/ligentia-logo.svg';
import BG from 'assets/tech_graphics.png'
import { LockOutlined, UserOutlined } from '@ant-design/icons';

const request = [{ required: true }];
interface LoginProps extends RouteComponentProps { }
interface LoginStates {
    loginLoding: boolean;
    activeKey: string;
    salt?: string;
    /** 倒计时 */
    countDown: number;
}

const validateMessages = {
    required: `'\${label}' is required fields`,
};

export default class ELogin extends React.Component<LoginProps, LoginStates> {

    state: LoginStates = {
        loginLoding: false,
        activeKey: 'phone',
        countDown: 0,
    };


    onFinish: FormProps['onFinish'] = async (values: Api.LoginAccounts.APostToken.Request) => {
        this.setState({ loginLoding: true });
        const res = await APostToken({
            userName: values.userName,
            password: values.password
        });
        if (res.success && res.data) {
            message.destroy()
            message.success('Login success');
            this.setState({ loginLoding: false });
            window.localStorage.setItem('userToken', `Bearer ${res.data.accessToken.token}`);
            this.loginSuccess();
        } else {
            this.setState({ loginLoding: false });
            message.error(res.message);
        }
    };

    loginSuccess = () => {
        const redirectUrl = GetUrlParams({ key: 'redirect' });
        const url = redirectUrl ? decodeURIComponent(redirectUrl) : '/welcome';
        window.location.replace(url)
    };

    render() {
        return (
            <main className="main-login">
                <Typography.Title className="logo">
                    <img src={logo} style={{ width: '200px' }} alt="logo"/>
                </Typography.Title>
                <img className="bg" src={BG} alt="background" />
                <div className="loginBox">
                    <Card bordered={false} style={{ borderRadius: '12px', boxShadow: '0px 0px 3px #dedede' }}>
                        <input type="text" style={{ display: 'none' }} />
                        <input type="password" style={{ display: 'none' }} />
                        <Form
                            size="large"
                            name="loginForm"
                            validateMessages={validateMessages}
                            labelCol={{ span: 24 }}
                            onFinish={this.onFinish}
                        >
                            <Form.Item label="Account" name="userName" rules={request}>
                                <Input prefix={<UserOutlined className="icon"/>} placeholder="Please input the account" autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Password" name="password" rules={request}>
                                <Input.Password prefix={<LockOutlined className="icon"/>} placeholder="Please input the password" autoComplete="off" />
                            </Form.Item>

                            <Form.Item style={{ textAlign: 'center', paddingTop: '24px' }}>
                                <Button
                                    className="btn"
                                    loading={this.state.loginLoding}
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#32AAE1'
                                    }}
                                >
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </main>
        );
    }
}
