import React from 'react';
import { connect } from "react-redux";
import { Button, Layout, Result, Space, Spin, Modal } from 'antd'
import { Switch, Route } from "react-router-dom";
import routes from '../routes/router-config'
import Authorized, { getCurrentRoute, getRouteList } from 'routes/authorized';
import { fetchPermissionData } from 'reducers';
import Logo from 'assets/ligentia-logo.svg'
import './layout-container-black-style.less'
import { AppstoreOutlined, DashboardOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import SwitchClient from './switch-client';

const { Header, Content } = Layout;

export interface LayoutProps {
    /** dispatch */
    dispatch?: any;
    /** dispatch */
    location?: any;
    /** dispatch */
    history?: any;
    /** 当前登录的用户名 */
    username?: string;
    tenantName?: string;
    tenantCount?: number;
    tenantLogo?: string;
    /** 权限接口是否请求完成 */
    layoutLoading?: boolean;
}

interface LayoutStates {
    pageLoading: boolean;
    isFireFox?: boolean;
    showSwitchClient: boolean;
}

class LayoutContainer extends React.Component<LayoutProps, LayoutStates>{

    state: LayoutStates = {
        pageLoading: true,
        showSwitchClient: false
    }

    componentDidMount() {
        this.getInitSetting();
        this.whatBrowser();
    }

    // 判断浏览器类型
    whatBrowser() {
        var userAgent = navigator.userAgent.toLowerCase();
        this.setState({
            isFireFox: !!userAgent && userAgent.indexOf('firefox') > 0
        })
    }

    getInitSetting = () => {
        this.setState({
            pageLoading: true
        }, () => {
            const { dispatch } = this.props;
            fetchPermissionData(dispatch).then(res => {
                setTimeout(() => {
                    this.setState({
                        pageLoading: false
                    })
                }, 1000);
            })
        })
    }

    renderMain = () => {
        const { location } = this.props;
        const { pathname } = location;
        const currentRoute = getCurrentRoute(routes, pathname);
        const routeList = getRouteList(routes);
        if (currentRoute) {
            return (
                <Switch>
                    {routeList.map(route => {
                        const { ...rest } = route;
                        return (
                            <Authorized {...rest} key={route.path} {...this.props}>
                                <Route path={route.path} component={route.component} />
                            </Authorized>
                        )
                    })}
                </Switch>
            )
        }
        return (
            <Result
                status="404"
                subTitle="Sorry, the page is not exist！"
                extra={
                    <Button type="primary">Back</Button>
                }
            />
        )
    }

    logout = () => {
        Modal.confirm({
            title: 'Logout',
            content: 'Confirm logout?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                window.localStorage.clear()
                window.location.replace(`/login?redirect=${encodeURIComponent(window.location.pathname)}`)
            }
        })
    }

    render() {
        const { username, tenantName, tenantCount, tenantLogo } = this.props;
        const { isFireFox } = this.state;
        if (!this.props.layoutLoading) {
            return (
                <Layout style={{ minHeight: '100vh' }} className={`website-root-layout ${isFireFox ? 'website-firefox-layout' : ''}`}>
                    <Layout className="layout-black">
                        <Content className="website-main-layout">
                            <Header className="header">
                                <div className="website-header-left">
                                    <div className="img-box">
                                        <img src={Logo} alt="logo" className="header-logo" />
                                    </div>
                                    <Space>
                                        <div className="menu-box">
                                            <DashboardOutlined />
                                            <span className="menu-text">Calculator</span>
                                        </div>
                                    </Space>
                                </div>
                                <div className="website-header-right">
                                    <Space size={40}>
                                        <div className="menu-btn">
                                            <Space><UserOutlined />{username}</Space>
                                        </div>
                                        {
                                            tenantCount !== undefined && tenantCount > 1 ?
                                                <div className="menu-btn" onClick={() => this.setState({ showSwitchClient: true })}>
                                                    <Space><AppstoreOutlined />{tenantName}</Space>
                                                    {/* <img src={tenantLogo} alt="logo"  style={{ width: '20px', height: '20px'}} /> */}
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="menu-btn" onClick={this.logout}>
                                            <Space><PoweroffOutlined />  Logout</Space>
                                        </div>
                                    </Space>
                                </div>
                            </Header>
                            <div className="main" style={{ padding: '30px 12px 12px 12px' }} >{this.renderMain()}</div>
                        </Content>
                    </Layout>
                    <SwitchClient
                        showModel={this.state.showSwitchClient}
                        onClose={() => this.setState({ showSwitchClient: false })}
                        key={this.state.showSwitchClient.toString()}/>
                </Layout>
                
            );
        }
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" tip="Loading..." />
            </div>
        )
    }
};

export default connect((store: any) => {
    return {
        username: store.username,
        tenantName: store.tenantName,
        tenantCount: store.tenantCount,
        tenantLogo: store.tenantLogo,
        layoutLoading: store.layoutLoading
    }
})(
    LayoutContainer
);