import { Button, Result } from "antd";
import React from "react";

export default class NotAllow extends React.Component {
    render() {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button type="primary">Back Home</Button>
                }
            />
        )
    }
}