import React from 'react';
// import Animate from 'rc-animate';
import Step1 from './Step/step1'
import Step2 from './Step/step2'
import Step3 from './Step/step3'
import Step4 from './Step/step4'
import moment from 'moment';
import { DateFormatEnum } from 'utils';
import { BreakDownsVo, SummariesVo, ResultVo, TotalsVo } from 'services/calculator';
import "./style.less"
import { connect } from 'react-redux';

interface StepProps {
    userName: string
}
interface StepState {
    currency: string;
    transportMode: string;
    summarySources: SummariesVo[];
    breakdownSources: BreakDownsVo[];
    totals: TotalsVo[];
    results: ResultVo[];
    step2Show: boolean;
    step3Show: boolean;
    step4Show: boolean;
    validTill: string;
    admRequestDate: string;
    brandProject: string;
    payImportVat: string;
    cargoReadyDate: string;
    insuranceRequired: boolean;
    ligentiaInvoice: string;
    ligentiaQuoteDate: string;
    ligentiaQuoteReference: string;
}
class Calculator extends React.Component<StepProps, StepState> {
    formRef = React.createRef();
    sendForm = React.createRef();
    importForm = React.createRef();
    firstEcharts = React.createRef();
    secondEcharts = React.createRef();

    constructor(props: StepProps) {
        super(props)
        this.state = {
            currency: 'USD',
            transportMode: 'SEA',
            summarySources: [],
            breakdownSources: [],
            totals: [],
            results: [],
            step2Show: false,
            step3Show: false,
            step4Show: false,
            validTill: moment().format(DateFormatEnum.date),
            admRequestDate: moment().format(DateFormatEnum.date),
            brandProject: '',
            payImportVat: '',
            cargoReadyDate: moment().format(DateFormatEnum.date),
            insuranceRequired: false,
            ligentiaInvoice: '',
            ligentiaQuoteDate: moment().format(DateFormatEnum.date),
            ligentiaQuoteReference: '',
        };
    }

    componentWillMount() {
        var date1 = new Date();
        var date2 = new Date(date1);
        date2.setDate(date1.getDate() + 30);
        var t = moment(date2).format(DateFormatEnum.minuteDate);
        this.setState({
            validTill: t
        });
    }

    render() {
        return (
            <div className="calculator-main">
                <Step1
                    handleStep1Change={(value, type) => this.handleStep1Change(value, type)}
                    showStep={(value) => this.showStep(value)}
                ></Step1>
                {this.state.step2Show ? (
                    // <Animate
                    //     transitionName="fade"
                    //     transitionAppear
                    // >
                    <Step2
                        key="step2"
                        handleGetResult={(value, index, isNew) => this.handleGetResult(value, index, isNew)}
                        deleteSummary={(value) => this.deleteSummary(value)}
                        deleteBreakdown={(value) => this.deleteBreakdown(value)}
                        deleteTotal={(value) => this.deleteTotal(value)}
                        deleteResult={(value) => this.deleteResult(value)}
                        currency={this.state.currency}
                        transportMode={this.state.transportMode}
                        summaries={this.state.summarySources}
                        breakDowns={this.state.breakdownSources}
                        totals={this.state.totals}
                        results={this.state.results}
                        showStep={(value) => this.showStep(value)}
                        validTill={this.state.validTill}
                        quoteRequestedBy={this.props.userName}
                        admRequestDate={this.state.admRequestDate}
                        brandProject={this.state.brandProject}
                        payImportVat={this.state.payImportVat}
                        cargoReadyDate={this.state.cargoReadyDate}
                        insuranceRequired={this.state.insuranceRequired}
                        ligentiaInvoice={this.state.ligentiaInvoice}
                        ligentiaQuoteDate={this.state.ligentiaQuoteDate}
                        ligentiaQuoteReference={this.state.ligentiaQuoteReference}
                    ></Step2>
                    // </Animate>
                ) : null
                }
                {this.state.step3Show ? (
                    // <Animate
                    //     transitionName="fade"
                    //     transitionAppear
                    // >
                    <Step3
                        key="step3"
                        totals={this.state.totals}
                        summaries={this.state.summarySources}
                        breakDowns={this.state.breakdownSources}
                        showStep={(value) => this.showStep(value)}
                    ></Step3>
                    // </Animate>
                ) : null}
                {this.state.step4Show ? (
                    // <Animate
                    //     transitionName="fade"
                    //     transitionAppear
                    // >
                    <Step4
                        results={this.state.results}
                    ></Step4>
                    // </Animate>
                ) : null}
            </div>
        )
    }



    showStep(value: string) {
        if (value === 'step2') {
            this.setState({
                step2Show: true
            });
        } else if (value === 'step3') {
            this.setState({
                step3Show: true
            });
        } else if (value === 'step4') {
            this.setState({
                step4Show: true
            });
        }
    }

    handleStep1Change(value: string, type: string) {
        switch (type) {
            case 'validTill':
                this.setState({
                    validTill: value === '' ? '' : value
                });
                break;
            case 'admRequestDate':
                this.setState({
                    admRequestDate: value === '' ? '' : value
                });
                break;
            case 'brandProject':
                this.setState({
                    brandProject: value === '' ? '' : value
                });
                break;
            case 'payImportVat':
                this.setState({
                    payImportVat: value === '' ? '' : value
                });
                break;
            case 'cargoReadyDate':
                this.setState({
                    cargoReadyDate: value === '' ? '' : value
                });
                break;
            case 'insuranceRequired':
                this.setState({
                    insuranceRequired: value === 'No' ? false : true
                });
                break;
            case 'ligentiaInvoice':
                this.setState({
                    ligentiaInvoice: value === '' ? '' : value
                });
                break;
            case 'ligentiaQuoteDate':
                this.setState({
                    ligentiaQuoteDate: value === '' ? '' : value
                });
                break;
            case 'ligentiaQuoteReference':
                this.setState({
                    ligentiaQuoteReference: value === '' ? '' : value
                });
                break;
            case 'currency':
                this.setState({
                    currency: value === '' ? '' : value
                });
                break;
            case 'transportMode':
                this.setState({
                    transportMode: value === '' ? '' : value
                });
                break;
        }
    }

    handleGetResult(value: ResultVo, index: number | undefined, isNew: boolean = true) {
        if (isNew) {
            let temp1 = [...this.state.summarySources];
            let temp2 = [...this.state.breakdownSources];
            let temp3 = [...this.state.results];
            let temp4 = [...this.state.totals];
            temp1.push({ ...value.summaries });
            temp2.push({ ...value.breakDowns });
            temp3.push({ ...value });
            temp4.push({ ...value.totals });
            this.setState({
                summarySources: temp1,
                breakdownSources: temp2,
                results: temp3,
                totals: temp4
            });
        } else {
            if (index !== undefined) {
                let { summarySources, breakdownSources, results, totals } = this.state;
                summarySources[index - 1] = { ...value.summaries}
                breakdownSources[index - 1] = { ...value.breakDowns};
                results[index - 1] = { ...value};
                totals[index - 1] = { ...value.totals};
                this.setState({
                    summarySources: summarySources,
                    breakdownSources: breakdownSources,
                    results: results,
                    totals: totals
                });
            }
        }
    }

    deleteSummary(value: SummariesVo[]) {
        this.setState({
            summarySources: value
        });
    }

    deleteBreakdown(value: BreakDownsVo[]) {
        this.setState({
            breakdownSources: value
        });
    }

    deleteTotal(value: TotalsVo[]) {
        this.setState({
            totals: value
        });
    }

    deleteResult(value: ResultVo[]) {
        this.setState({
            results: value
        });
    }

    currencyChange(value: string | undefined) {
        if (value === undefined) {
            value = '';
        }
        this.setState({
            currency: value === '' ? '' : value
        });
    }
}

export default connect((store: any) => {
    return {
        userName: store.username
    }
})(Calculator)