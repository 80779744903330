import request from "../utils/request";

export interface InputVo {
    admRequestDate: string;
    brandProject: string;
    cargoReadyDate: string;
    currency: string;
    currencyExChange: number;
    deliveryLocation: string;
    destinationPod: string;
    gscCommission: number;
    height: number;
    hsCode: string;
    indicateQuantity: number;
    insuranceRequired: false
    itemDescription: string;
    itemId: string;
    length: number;
    ligentiaInvoice: string;
    ligentiaQuoteDate: string;
    ligentiaQuoteReference: string;
    mode: string;
    newUnitPrice: number;
    originPol: string;
    palletization: string;
    payImportVat: string;
    pcsCarton: number;
    quoteRequestedBy: string;
    random: number;
    termSelection: string;
    transportMode: string;
    validTill: string;
    weight: number;
    width: number;
}
export interface BreakDownsVo {
    key?: number,
    clearance: number;
    delivery: number;
    doFee: number;
    documentation: number;
    duty: number;
    dutyPercent: number;
    fortyCost: number;
    fortyGpContainer: number;
    freight: number;
    gst: number;
    gstPercent: number;
    handling: number;
    insurance: number;
    itemDescription: string;
    lclCost: number;
    mode: string;
    numLcl: number;
    random: number;
    thc: number;
    totalCbm: number;
    totalWeight: number;
    twentyCost: number;
    twentyGpContainer: number;
    warehouse: number;
    warehouseDelivery: number;
}
export interface TotalsVo {
    key?: number,
    random: number;
    totalProductPrice: number;
    totalGst: number;
    totalLogisticsCost: number;
    totalDuty: number;
    totalLandedCost: number;
}

export interface UnitsVo {
    unitLandedCost: number;
    unitProductPrice: number;
    unitLogisticsCost: number;
    unitDuty: number;
    unitGst: number
}

export interface ResultPo {
    itemId: string;
    itemDescription?: string;
    originPol?: string;
    destinationPod?: string;
    indicateQuantity?: number;
    newUnitPrice?: number;
    pcsCarton?: number;
    weight?: number;
    length?: number;
    width?: number;
    height?: number;
    currency?: string;
    palletization?: string;
    hsCode?: string;
    termSelection?: string;
    mode?: string;
    transportMode?: string;
    validTill: Date;
    admRequestDate?: Date;
    cargoReadyDate?: Date;
    ligentiaQuoteDate?: Date;
    quoteRequestedBy?: string;
    brandProject?: string;
    payImportVat?: string;
    insuranceRequired?: boolean;
    ligentiaInvoice?: string;
    ligentiaQuoteReference?: string;
}

export interface SummariesVo {
    key?: number,
    clearance: number;
    commericalValue: number;
    ctnCbm: number;
    ddpPricePPiece: number;
    delivery: number;
    doFee: number;
    documentation: number;
    duty: number;
    dutyPercent: number;
    fortyCost: number;
    fortyGpContainer: number;
    freight: number;
    freightAndDutyPerPiece: number;
    freightPlusDutyPercent: number;
    gst: number;
    gstPercent: number;
    handling: number;
    insurance: number;
    itemDescription: string;
    lclCost: number;
    mode: string;
    numLcl: number;
    random: number;
    thc: number;
    totalCbm: number;
    totalCtns: number;
    totalDutyAndTaxOutlay: number;
    totalGrossWeightKg: number;
    totalNettWeightKg: number;
    totalShippingCostsInclDutyPlusTax: number;
    totalShippingExclDutyPlusTax: number;
    totalWeight: number;
    twentyCost: number;
    twentyGpContainer: number;
    warehouse: number;
    warehouseDelivery: number;
}

export interface ResultVo {
    random?: number,
    totals: TotalsVo;
    breakDowns: BreakDownsVo;
    summaries: SummariesVo;
    inputs: InputVo;
}
export interface ItemsDataVo {
    id: number;
    itemId: string;
}

export interface PolsDataVo {
    pol: string;
}

export interface PodsDataVo {
    pod: string;
    podCountry: string;
}

export interface ResponseVo<T> {
    data: DataVo<T>;
    success: boolean;
    message: string;
    code: number;
}

export interface DataVo<T> {
    values: Array<T>;
}

export interface ResponseVo1<T> {
    data: T;
    success: boolean;
    message: string;
    code: number;
}

export function GetTotalLandedCost(params: ResultPo) {
    return request<ResponseVo1<ResultVo>>('/api/calculator/totalLandedCost', {
        method: 'post', data: params
    });
}

export function GetItems(params: { keyword?: string }) {
    return request<ResponseVo<ItemsDataVo>>('/api/datadic/items', {
        method: 'get', params
    });
}


export function GetPols(params?: { keyword: string }) {
    return request<ResponseVo<PolsDataVo>>('/api/datadic/pols', {
        method: 'get', params
    });
}

export function GetPods(params?: { keyword?: string }) {
    return request<ResponseVo<PodsDataVo>>('/api/datadic/pods', {
        method: 'get', params
    });
}

export interface RateDataVo {
    countryCode: string;
    deliveryLocation: string;
    portOfArrival: string;
}

export function GetRates(params?: { keyword?: string }) {
    return request<ResponseVo<RateDataVo>>('/api/datadic/rates', {
        method: 'get', params
    });
}

export interface HsCodeDataVo {
    hsCode: string;
    duty: string;
    gst: string;
    description: string;
}

export function GetHsCodes(params: {
    keyword?: string,
    podCountry?: string,
    pageSize: number,
    pageIndex: number
}) {
    return request<ResponseVo<HsCodeDataVo>>('/api/datadic/hsCodes', {
        method: 'get', params
    });
}

export function SendEmailV2(params: {
    results: ResultVo[]
}) {
    return request<ResponseVo1<{}>>('/api/email/sendV2', {
        method: 'post', data: params
    });
}