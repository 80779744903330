import request from "../utils/request";

/**
 ** 接口名称: Accounts
 ** 接口地址: /api/Accounts/Token
 ** 请求方式: POST
 ** 接口描述: 获取登录用户详细信息
 */
export function APostToken(data: Api.LoginAccounts.APostToken.Request) {
  return request<Api.LoginAccounts.APostToken.Response>('/api/account/login', {
    method: 'post',
    data,
  });
}

/**
 ** 接口名称: Accounts
 ** 接口地址: /api/Accounts/Detail
 ** 请求方式: GET
 ** 接口描述: 获取用户信息
 */
export function AGetDetail() {
  return request<Api.LoginAccounts.AGetDetail.Response>('/api/account/detail', {
    method: 'get',
  });
}

export function AGetGetInfraUserTenantList() {
  return request<Api.LoginAccounts.AGetGetInfraUserTenantList.Response>('/api/account/GetInfraUserTenantList', {
    method: 'get',
  });
}

export function APutSwitchTenant(data: Api.LoginAccounts.APutSwitchTenant.Request) {
  return request<Api.LoginAccounts.AGetGetInfraUserTenantList.Response>('/api/account/SwitchTenant', {
    method: 'put',
    data
  });
}