import React from 'react';
import { Table, Select, Input, Radio, Button, Spin, Space, InputNumber, RadioChangeEvent, notification } from 'antd';
import { MinusSquareOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { BreakDownsVo, SummariesVo, GetHsCodes, GetPods, GetPols, GetRates, GetTotalLandedCost, ResultPo, ResultVo, TotalsVo } from 'services/calculator';
import { changeMoney, inputNumFormatterkilo } from 'utils';
import { NextButton } from 'utils/theme';
const { Option } = Select;

interface Step2Props {
    breakDowns: Array<BreakDownsVo>;
    summaries: Array<SummariesVo>;
    totals: Array<TotalsVo>;
    results: Array<ResultVo>;
    currency: string;
    validTill: string;
    admRequestDate: string;
    cargoReadyDate: string;
    ligentiaQuoteDate: string;
    transportMode: string;
    quoteRequestedBy: string;
    brandProject: string;
    payImportVat: string;
    insuranceRequired: boolean;
    ligentiaInvoice: string;
    ligentiaQuoteReference: string;
    handleGetResult: (data: ResultVo, index?: number, isNew?: boolean) => void;
    deleteBreakdown: (data: BreakDownsVo[]) => void;
    deleteSummary: (data: SummariesVo[]) => void;
    deleteTotal: (data: TotalsVo[]) => void;
    deleteResult: (data: ResultVo[]) => void;
    showStep: (step: string) => void;
}

interface RowPo {
    key: number;
    podCountry?: string,
    itemDescription?: string,
    originPol?: string,
    destinationPod?: string,
    newUnitPrice?: number,
    piecesQTY?: number,
    piecesPCarton?: number,
    ctnGw?: number,
    length?: number,
    width?: number,
    height?: number,
    weight?: number;
    indicateQuantity?: number;
    pcsCarton?: number;
    palletization?: string,
    hsCode?: string,
    buyingIncoterm?: string,
    mode?: string
    sellingIncoterm?: string,
    gscCommission?: number,
    deliveryLocation?: string,
    hsValue?: string;
    random?: number;
}

interface Step2State {
    pols: Array<{ pol: string }>,
    pods: Array<{
        pod: string,
        podCountry: string,
    }>,
    rates: Array<{
        countryCode: string,
        deliveryLocation: string,
        portOfArrival: string,
    }>,
    hsCodes: Array<{
        hsCode: string,
        description: string
    }>,
    dlDisabled: boolean,
    podDisabled: boolean,
    dataSource: Array<RowPo>,
    addRow: RowPo,
    addRowKey: number,
    editingKey: number,
    editRow?: RowPo;
    fetching: boolean;
    gscCommission: number;
}
export default class Step2 extends React.Component<Step2Props, Step2State> {

    pageSize = 100;

    pageIndex = 1;

    timer: NodeJS.Timeout = {} as NodeJS.Timeout;

    mode = [
        { label: 'FCL/AUTO', value: 'FCL' },
        { label: 'LCL', value: 'LCL' }
    ]

    columns: ColumnsType<RowPo> = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: '0.5%',
            render: (text, record, index) => record.key
        },
        {
            title: () => { return <span>Item,<br />material description</span> },
            dataIndex: 'itemDescription',
            key: 'itemDescription',
            align: 'center',
            width: '10%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Input
                        defaultValue={record.itemDescription}
                        className="border-input"
                        onInput={(value) => this.paramChange(value, 'itemDescription')}
                        style={{ textAlign: 'right' }}
                        value={record.key === this.state.editingKey ? this.state.editRow?.itemDescription : this.state.addRow.itemDescription}
                    />
            }
        },
        {
            title: 'Buying INCOTERM',
            dataIndex: 'buyingIncoterm',
            key: 'buyingIncoterm',
            align: 'center',
            width: '5%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Select
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => this.termChange(value, "buyingIncoterm")}
                        style={{ width: '75px' }}
                        defaultValue={record.buyingIncoterm || 'FOB'}
                        value={record.key === this.state.editingKey ? this.state.editRow?.buyingIncoterm : (this.state.addRow.buyingIncoterm || 'FOB')}
                    >
                        <Option value="EXW">EXW</Option>
                        <Option value="FOB">FOB</Option>
                    </Select>
            }
        },
        {
            title: 'Mode',
            dataIndex: 'mode',
            key: 'mode',
            align: 'center',
            width: '5%',
            render: (value, record, index) => {
                let label = ''
                for (let i = 0; i < this.mode.length; i++){
                    if (this.mode[i].value === value) {
                        label = this.mode[i].label
                    }
                }
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? label :
                    <Select
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => this.termChange(value, "mode")}
                        style={{ width: '75px' }}
                        defaultValue={record.mode || this.mode[0].value}
                        value={record.key === this.state.editingKey ? this.state.editRow?.mode : (this.state.addRow.mode || this.mode[0].value)}
                    >  
                        { this.mode.map(item => <Option value={item.value}>{item.label}</Option>) }
                    </Select>
            }
        },
        {
            title: 'Palletisation before delivery?',
            dataIndex: 'palletization',
            key: 'palletization',
            align: 'center',
            width: '10%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Radio.Group size="small"
                        defaultValue={record.palletization || 'No'}
                        buttonStyle="solid"
                        onChange={(value) => this.palletizationClick(value)}
                        value={record.key === this.state.editingKey ? this.state.editRow?.palletization : (this.state.addRow.palletization || 'No')}
                    >
                        <Radio.Button value="Yes" className="radio-btn">YES</Radio.Button>
                        <Radio.Button value="No" className="radio-btn">NO</Radio.Button>
                        <Radio.Button value="by Vendor" className="radio-btn">By Vendor</Radio.Button>
                    </Radio.Group>
            }
        },
        {
            title: 'Port of Departure',
            dataIndex: 'originPol',
            key: 'originPol',
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Select
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        filterOption={false}
                        style={{ width: '121px' }}
                        onFocus={() => this.polFocus()}
                        onSearch={(value) => this.polSearch(value)}
                        onChange={(value) => this.polChange(value)}
                        notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                        defaultValue={record.originPol}
                        value={record.key === this.state.editingKey ? this.state.editRow?.originPol : this.state.addRow.originPol}
                    >
                        {this.state.pols.map(item => (
                            <Option key={item.pol} value={item.pol}>{item.pol}</Option>
                        ))}
                    </Select>
            }
        },
        {
            title: 'Delivery Location',
            dataIndex: 'deliveryLocation',
            key: 'deliveryLocation',
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Select
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        filterOption={false}
                        style={{ width: '120px' }}
                        onFocus={() => this.rateFocus()}
                        onSearch={(value) => this.rateSearch(value)}
                        onChange={(value) => this.rateChange(value)}
                        notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                        disabled={this.state.dlDisabled}
                        value={record.key === this.state.editingKey ? this.state.editRow?.deliveryLocation : this.state.addRow.deliveryLocation}
                    >
                        {this.state.rates.map((item, index) => (
                            <Option key={index} value={item.countryCode + '@' + item.deliveryLocation + '@' + item.portOfArrival}>{item.deliveryLocation}</Option>
                        ))}
                    </Select>
            }
        },
        {
            title: 'Port of Arrival',
            dataIndex: 'destinationPod',
            key: 'destinationPod',
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Select
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        filterOption={false}
                        style={{ width: '120px' }}
                        onFocus={() => this.podFocus()}
                        onSearch={(value) => this.podSearch(value)}
                        onChange={(value) => this.podChange(value)}
                        notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                        disabled={this.state.podDisabled}
                        value={record.key === this.state.editingKey ? this.state.editRow?.destinationPod : this.state.addRow.destinationPod}
                    >
                        {this.state.pods.map(item => (
                            <Option key={item.pod + ',' + item.podCountry} value={item.pod + ',' + item.podCountry}>{item.pod}</Option>
                        ))}
                    </Select>
            }
        },
        {
            title: 'HS Code',
            dataIndex: 'hsCode',
            key: 'hsCode',
            align: 'center',
            width: '4%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? record.hsValue :
                    <Select
                        dropdownMatchSelectWidth={false}
                        showSearch
                        allowClear
                        filterOption={false}
                        style={{ width: '125px' }}
                        onFocus={() => this.hsCodeFocus()}
                        onSearch={(value) => this.hsCodeSearch(value)}
                        onChange={(value) => this.hsCodeChange(value)}
                        notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                        defaultValue={record.hsValue}
                        value={record.key === this.state.editingKey ? this.state.editRow?.hsValue : this.state.addRow.hsValue}
                        dropdownStyle={{ width: '360px' }}
                        className="code-select"
                        dropdownClassName="code-dropdown-select"
                    >
                        {this.state.hsCodes.map((item, index) => (
                            <Option key={item.hsCode + '-' + item.description} value={item.hsCode + '-' + item.description}>{item.hsCode + '-' + item.description}</Option>
                        ))}
                    </Select>
            }
        },
        {
            title: () => {
                return <span>Unit Price <br />{this.props.currency === '' ? '' : "(" + this.props.currency + ")"}</span>
            },
            dataIndex: 'newUnitPrice',
            key: 'newUnitPrice',
            align: 'center',
            width: '4%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? changeMoney(text) :
                    <InputNumber
                        {...inputNumFormatterkilo}
                        className="border-input"
                        style={{ textAlign: 'right' }}
                        precision={2}
                        min={0}
                        onChange={(value) => this.numberChange(value, 'newUnitPrice')}
                        defaultValue={record.newUnitPrice}
                        value={record.key === this.state.editingKey ? this.state.editRow?.newUnitPrice : this.state.addRow.newUnitPrice}
                    />
            }
        },
        {
            title: 'GSC Commission%',
            dataIndex: 'gscCommission',
            key: 'gscCommission',
            align: 'center',
            width: '5%',
            render: (text, record, index) => {
                return (record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? `${text || 0}%` :
                    <span><InputNumber
                        className="border-input"
                        style={{ textAlign: 'right', width: '50px' }}
                        precision={2}
                        min={0}
                        // controls={false}
                        onChange={(value) => this.numberChange(value, 'gscCommission')}
                        defaultValue={record.gscCommission}
                        value={record.key === this.state.editingKey ? this.state.editRow?.gscCommission : this.state.addRow.gscCommission}
                    /> <span style={{ fontSize: '12px' }}>%</span></span>)
            }
        },
        {
            title: 'Pieces QTY',
            dataIndex: 'piecesQTY',
            key: 'piecesQTY',
            align: 'center',
            width: '6%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? changeMoney(text) :
                    <InputNumber
                        {...inputNumFormatterkilo}
                        className="border-input"
                        style={{ textAlign: 'right' }}
                        precision={0}
                        min={0}
                        // controls={false}
                        onChange={(value) => this.numberChange(value, 'piecesQTY')}
                        defaultValue={record.piecesQTY}
                        value={record.key === this.state.editingKey ? this.state.editRow?.piecesQTY : this.state.addRow.piecesQTY}
                    />
            }
        },
        {
            title: 'Selling INCOTERM',
            dataIndex: 'sellingIncoterm',
            key: 'sellingIncoterm',
            align: 'center',
            width: '5%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Select
                        dropdownMatchSelectWidth={false}
                        allowClear
                        style={{ width: '85px', textAlign: 'right' }}
                        onChange={(value) => this.termChange(value, "sellingIncoterm")}
                        defaultValue={record.sellingIncoterm}
                        value={record.key === this.state.editingKey ? this.state.editRow?.sellingIncoterm : this.state.addRow.sellingIncoterm}
                    >
                        <Option value="CPT">CPT</Option>
                        <Option value="DAP">DAP</Option>
                        <Option value="DDP EXCL GST">DDP EXCL GST</Option>
                        <Option value="DDP INCL GST">DDP INCL GST</Option>
                    </Select>
            }
        },
        {
            title: () => { return <span>Carton Measurement <br /> (L x W x H)(cm)</span> },
            dataIndex: 'cartonMeasurement',
            key: 'cartonMeasurement',
            align: 'center',
            width: '13%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? text :
                    <Input.Group compact>
                        <InputNumber
                            defaultValue={record.length}
                            value={record.key === this.state.editingKey ? this.state.editRow?.length : this.state.addRow.length}
                            className="border-input"
                            precision={0}
                            min={0}
                            onChange={(value) => this.numberChange(value, 'length')}
                            style={{ width: '25%', textAlign: 'right' }} />×
                        <InputNumber
                            defaultValue={record.width}
                            value={record.key === this.state.editingKey ? this.state.editRow?.width : this.state.addRow.width}
                            className="border-input"
                            precision={0}
                            min={0}
                            onChange={(value) => this.numberChange(value, 'width')}
                            style={{ width: '25%', textAlign: 'right' }} />×
                        <InputNumber
                            defaultValue={record.height}
                            value={record.key === this.state.editingKey ? this.state.editRow?.height : this.state.addRow.height}
                            className="border-input"
                            precision={0}
                            min={0}
                            onChange={(value) => this.numberChange(value, 'height')}
                            style={{ width: '25%', textAlign: 'right' }} />
                    </Input.Group>
            }
        },
        {
            title: () => { return <span>Pieces <br /> p/ Carton</span> },
            dataIndex: 'piecesPCarton',
            key: 'piecesPCarton',
            align: 'center',
            width: '4%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? changeMoney(text) :
                    <InputNumber
                        value={record.key === this.state.editingKey ? this.state.editRow?.piecesPCarton : this.state.addRow.piecesPCarton}
                        className="border-input"
                        precision={0}
                        min={0}
                        // controls={false}
                        onChange={(value) => this.numberChange(value, 'piecesPCarton')}
                        style={{ width: '100%', textAlign: 'right' }}
                    />
            }
        },
        {
            title: 'CTN GW',
            dataIndex: 'ctnGw',
            key: 'ctnGw',
            align: 'center',
            width: '4%',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ? changeMoney(text) :
                    <InputNumber
                        defaultValue={record.ctnGw}
                        value={record.key === this.state.editingKey ? this.state.editRow?.ctnGw : this.state.addRow.ctnGw}
                        className="border-input"
                        precision={2}
                        min={0}
                        // controls={false}
                        onChange={(value) => this.numberChange(value, 'ctnGw')}
                        style={{ width: '100%', textAlign: 'right' }}
                    />
            }
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record, index) => {
                return record.key !== this.state.addRowKey && record.key !== this.state.editingKey ?
                    <Space>
                        <Button type="link" title="remove" onClick={() => this.removeDataSource(record)} icon={<MinusSquareOutlined />} />
                        <Button type="link" title="edit" onClick={() => this.dataSourceEdit(record)} icon={<EditOutlined />} />
                        <Button type="link" title="copy" onClick={() => this.copyDataSource(record)} icon={<CopyOutlined />} />
                    </Space> :
                    <Space>
                        {this.state.editingKey === record.key && <Button size="small" onClick={() => this.dataSourceCancel()}>CANCEL</Button>}
                        <NextButton
                            style={{ width: '60px' }}
                            onClick={() => {
                                if (record.key === this.state.editingKey) {
                                    this.editSave(record)
                                } else {
                                    this.getResult(record)
                                }
                            }}
                            onKeyDown={() => {
                                debugger
                                if (record.key === this.state.editingKey) {
                                    this.editSave(record)
                                } else {
                                    this.getResult(record)
                                }
                            }}
                        >
                            SAVE
                        </NextButton>
                    </Space>
            }
        }
    ]

    constructor(props: Step2Props) {
        super(props)
        this.state = {
            pols: [],
            pods: [],
            rates: [],
            hsCodes: [],
            dlDisabled: true,
            podDisabled: false,
            dataSource: [
                {
                    key: 1
                }
            ],
            addRow: {
                key: 1,
                podCountry: '',
                itemDescription: '',
                originPol: '',
                destinationPod: '',
                newUnitPrice: 0,
                piecesQTY: 0,
                piecesPCarton: 0,
                ctnGw: 0,
                length: 0,
                width: 0,
                height: 0,
                palletization: 'No',
                hsCode: '',
                buyingIncoterm: 'FOB',
                sellingIncoterm: '',
                gscCommission: 0,
                deliveryLocation: '',
                mode: this.mode[0].value
            },
            addRowKey: 1,
            editingKey: 0,
            editRow: undefined,
            fetching: false,
            gscCommission: 0
        };
    }

    dataSourceEdit = (record: RowPo) => {
        this.setState({
            editingKey: record.key,
            gscCommission: record.gscCommission || 0,
            editRow: {
                ...record,
                newUnitPrice: record.newUnitPrice && record.newUnitPrice !== 0 ? record.newUnitPrice : 0,
                piecesQTY: record.piecesQTY && record.piecesQTY !== 0 ? record.piecesQTY : 0,
                ctnGw: record.ctnGw && record.ctnGw !== 0 ? record.ctnGw : 0,
                piecesPCarton: record.piecesPCarton && record.piecesPCarton !== 0 ? record.piecesPCarton : 0,
            },
            dlDisabled: record.palletization === 'Yes' || record.palletization === 'by Vendor' ? false : true,
            podDisabled: record.palletization === 'No' ? false : true,
        })
    };

    dataSourceCancel = () => {
        this.setState({
            editingKey: 0
        })
    };

    render() {
        return (
            <div className="step2-section">
                <section className="code-box-meta markdown">
                    <div className="code-box-title">
                        <div><span className="step-number">Step2: </span>Input the cargo information</div>
                    </div>
                    <div className="code-box-description">

                        <Table
                            className='step2table'
                            size={'small'}
                            rowClassName="editable-row"
                            pagination={false}
                            bordered
                            columns={this.columns}
                            dataSource={this.state.dataSource}
                        />
                    </div>
                </section>
            </div>
        )
    }

    //#region pols
    polChange(value: string) {
        const { editRow, editingKey, addRow } = this.state;
        if (editingKey) {
            this.setState({
                editRow: { ...editRow, key: editingKey, originPol: value || '' }
            });
        } else {
            this.setState({
                addRow: { ...addRow, originPol: value || '' }
            });
        }
    }

    async polSearch(value: string) {
        this.setState({
            fetching: true,
        });

        const res = await GetPols({ keyword: value })
        if (res.success) {
            this.setState({
                pols: res.data.values,
                fetching: false
            })
        }
    }

    async polFocus() {
        var data = localStorage.getItem('pols');
        if (data !== null) {
            this.setState({
                pols: JSON.parse(data || '')
            })
        } else {
            this.setState({
                fetching: true,
            });
            const res = await GetPols()
            if (res.success) {
                this.setState({
                    pols: res.data.values,
                    fetching: false
                })
            }
        }
    }
    //#endregion
    //#region pods
    podChange(value?: string) {
        const { addRow, editRow, editingKey } = this.state;
        if (value) {
            const list = value.split(',');
            if (editingKey) {
                this.setState({
                    editRow: {
                        ...editRow,
                        key: editingKey,
                        destinationPod: value === undefined ? '' : list[0],
                        podCountry: value === undefined ? '' : list[1]
                    }
                })
            } else {
                this.setState({
                    addRow: {
                        ...addRow,
                        destinationPod: value === undefined ? '' : list[0],
                        podCountry: value === undefined ? '' : list[1]
                    }
                })

            }
        } else {
            if (editingKey) {
                this.setState({
                    editRow: {
                        ...editRow,
                        key: editingKey,
                        destinationPod: '',
                        podCountry: ''
                    }
                })
            } else {
                this.setState({
                    addRow: {
                        ...addRow,
                        destinationPod: '',
                        podCountry: ''
                    }
                })

            }
        }
    }

    async podSearch(value: string) {
        const res = await GetPods({ keyword: value })
        if (res.success) {
            this.setState({
                pods: res.data.values
            })
        }
    }

    async podFocus() {
        var data = localStorage.getItem('pods');
        if (data !== null) {
            this.setState({
                pods: JSON.parse(data || '')
            })
        } else {
            this.setState({
                fetching: true,
            });
            const res = await GetPods()
            if (res.success) {
                this.setState({
                    pods: res.data.values,
                    fetching: false
                })
            }
        }
    }
    //#endregion
    //#region rates
    rateChange(value: string) {
        const { editRow, addRow, editingKey } = this.state;
        if (editingKey) {
            this.setState({
                editRow: {
                    ...editRow,
                    key: editingKey,
                    deliveryLocation: value ? value.split('@')[1] : '',
                    podCountry: value ? value.split('@')[0] : '',
                    destinationPod: value ? value.split('@')[2] : ''
                }
            });
        } else {
            this.setState({
                addRow: {
                    ...addRow,
                    deliveryLocation: value ? value.split('@')[1] : '',
                    podCountry: value ? value.split('@')[0] : '',
                    destinationPod: value ? value.split('@')[2] : ''
                }
            })
        }
    }

    async rateSearch(value: string) {
        const res = await GetRates({ keyword: value })
        if (res.success) {
            this.setState({
                rates: res.data.values
            })
        }
    }

    async rateFocus() {
        var data = localStorage.getItem('rates');
        if (data !== null) {
            this.setState({
                rates: JSON.parse(data || '')
            })
        } else {
            this.setState({
                fetching: true,
            });
            const res = await GetRates()
            if (res.success) {
                this.setState({
                    rates: res.data.values,
                    fetching: false
                })
            }
        }
    }
    //#endregion
    //#region HsCode
    hsCodeChange(value: string | undefined) {
        const { addRow, editingKey, editRow } = this.state;
        if (editingKey) {
            this.setState({
                editRow: {
                    ...editRow,
                    key: editingKey,
                    hsValue: value,
                    hsCode: value === undefined ? '' : value.split('-')[0],
                }
            })
        } else {
            this.setState({
                addRow: {
                    ...addRow,
                    hsValue: value,
                    hsCode: value === undefined ? '' : value.split('-')[0],
                }
            })
        }
    }

    hsCodeSearch(value: string) {
        const searchPodCountry = this.state.editingKey ? this.state.editRow?.podCountry : this.state.addRow.podCountry;
        this.setState({
            hsCodes: [],
            fetching: true,
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            const res = await GetHsCodes({
                keyword: value,
                podCountry: searchPodCountry,
                pageSize: 100,
                pageIndex: 1
            })
            if (res.success) {
                this.setState({
                    hsCodes: res.data.values,
                    fetching: false
                })

            }
        }, 200);
    }

    async hsCodeFocus() {
        const searchPodCountry = this.state.editingKey ? this.state.editRow?.podCountry : this.state.addRow.podCountry;
        this.setState({
            fetching: true,
        });
        const res = await GetHsCodes({
            podCountry: searchPodCountry,
            pageSize: 100,
            pageIndex: 1
        })
        if (res.success) {
            this.setState({
                hsCodes: res.data.values,
                fetching: false
            })

        }
    }

    async getSaveResult(item: RowPo, random?: number, index?: number, isNew: boolean = true) {
        const that = this;
        const newItem: RowPo = { ...item, random: random };
        const keysList = Object.keys(newItem)
        keysList.forEach(key => {
            if (key === "length" || key === "width" || key === "height" || key === "newUnitPrice") {
                newItem[key] = newItem[key] ? Number(newItem[key] || 0) : 0
            }
            if (key === "gscCommission") {
                newItem.gscCommission = newItem.gscCommission ? newItem.gscCommission / 100 : 0
            }
            if (key === 'ctnGw') {
                newItem.weight = newItem[key] ? Number(newItem[key] || 0) : 0;
            }
            if (key === 'piecesQTY') {
                newItem.indicateQuantity = newItem[key] ? Number(newItem[key] || 0) : 0;
            }
            if (key === 'piecesPCarton') {
                newItem.pcsCarton = newItem[key] ? Number(newItem[key] || 0) : 0;
            }
        })
        var d1 = this.props.validTill;
        var d2 = this.props.admRequestDate;
        var d3 = this.props.cargoReadyDate;
        var d4 = this.props.ligentiaQuoteDate;
        const postParams: ResultPo = {
            ...newItem,
            termSelection: item.buyingIncoterm && item.sellingIncoterm ? `${item.buyingIncoterm} - ${item.sellingIncoterm}` : '',
            itemId: 'New Item',
            currency: this.props.currency,
            transportMode: this.props.transportMode,
            validTill: new Date(d1),
            quoteRequestedBy: this.props.quoteRequestedBy,
            admRequestDate: new Date(d2),
            brandProject: this.props.brandProject,
            payImportVat: this.props.payImportVat,
            cargoReadyDate: new Date(d3),
            insuranceRequired: this.props.insuranceRequired,
            ligentiaInvoice: this.props.ligentiaInvoice,
            ligentiaQuoteDate: new Date(d4),
            ligentiaQuoteReference: this.props.ligentiaQuoteReference,
        }
        const res = await GetTotalLandedCost(postParams);
        if (res.success) {
            that.props.handleGetResult(res.data, index, isNew);
        } else {
            notification.error({
                message: res.message
            })
        }
    }

    copyDataSource(item: RowPo) {
        this.setState({
            addRow: { ...item }
        })
    }

    editSave = async (record: RowPo) => {
        const newData = [...this.state.dataSource];
        const { editRow } = this.state;
        if (editRow) {
            const index = newData.findIndex((item) => item.key === record.key);
            const newItem = {
                ...record,
                ...editRow,
                cartonMeasurement: `${editRow.length || record.length}x${editRow.width || record.width}x${editRow.height || record.height}`,
                action: '',
            }
            newData.splice(index, 1, newItem);
            this.setState({
                dataSource: newData,
                editingKey: 0
            })
            this.getSaveResult({ ...record, ...editRow }, record.random, record.key, false)
        }
    };

    getResult(record: RowPo) {
        var timestamp = (new Date()).getTime();
        const { addRow } = this.state;
        let { dataSource } = this.state;
        var newData = {
            ...addRow,
            random: timestamp,
            cartonMeasurement: `${addRow.length || 0}x${addRow.width || 0}x${addRow.height || 0}`,
            action: '',
            key: record.key
        };
        dataSource = this.removeLastSource(dataSource);
        dataSource.push(newData);
        dataSource.push({ key: record.key + 1 });
        this.setState({
            dataSource: dataSource,
            addRowKey: record.key + 1,
            addRow: {
                key: record.key + 1,
                podCountry: '',
                itemDescription: '',
                originPol: '',
                destinationPod: '',
                newUnitPrice: 0,
                piecesQTY: 0,
                piecesPCarton: 0,
                ctnGw: 0,
                length: 0,
                width: 0,
                height: 0,
                palletization: 'No',
                hsCode: '',
                hsValue: '',
                buyingIncoterm: 'FOB',
                sellingIncoterm: '',
                gscCommission: 0,
                deliveryLocation: '',
                mode: this.mode[0].value
            },
            dlDisabled: true,
            podDisabled: false,
        });
        this.getSaveResult({ ...addRow }, timestamp, newData.key)
        this.props.showStep('step3');
    }

    palletizationClick(event: RadioChangeEvent) {
        const val = event.target.value;
        const { addRow, editRow, editingKey } = this.state;
        if (editingKey) {
            this.setState({
                editRow: {
                    ...editRow,
                    key: editingKey,
                    destinationPod: '',
                    deliveryLocation: '',
                    hsValue: '',
                    hsCode: '',
                    palletization: val,
                },
                dlDisabled: val === 'Yes' || val === 'by Vendor' ? false : true,
                podDisabled: val === 'No' ? false : true,
            });
        } else {
            this.setState({
                addRow: {
                    ...addRow,
                    destinationPod: '',
                    deliveryLocation: '',
                    hsValue: '',
                    hsCode: '',
                    palletization: val,
                },
                dlDisabled: val === 'Yes' || val === 'by Vendor' ? false : true,
                podDisabled: val === 'No' ? false : true,
            });

        }
    }

    termChange(value: string, type: string) {
        const { addRow, editRow, editingKey } = this.state;
        if (editingKey) {
            this.setState({
                editRow: {
                    ...editRow,
                    key: editingKey,
                    [`${type}`]: value
                }
            });
        } else {
            this.setState({
                addRow: {
                    ...addRow,
                    [`${type}`]: value
                }
            });
        }
    }

    numberChange(val: number, type: string) {
        var { addRow, editRow, editingKey } = this.state;
        if (editingKey) {
            this.setState({
                editRow: {
                    ...editRow,
                    key: editingKey, [`${type}`]: val
                }
            });
        } else {
            this.setState({
                addRow: { ...addRow, [`${type}`]: val }
            })
        }
    }

    paramChange(val: any, type: string) {
        var { addRow, editRow, editingKey } = this.state;
        if (editingKey) {
            this.setState({
                editRow: {
                    ...editRow,
                    key: editingKey, [`${type}`]: val.target.value
                }
            });
        } else {
            this.setState({
                addRow: { ...addRow, [`${type}`]: val.target.value }
            })
        }
    }

    removeLastSource(arr: RowPo[]) {
        var m = arr.slice(0);
        m.splice(m.length - 1, 1);
        return m;
    }

    // base on random number
    removeDataSource(item: RowPo) {
        let temp1 = [...this.state.dataSource];
        var index = temp1.indexOf(item);
        temp1.splice(index, 1);
        temp1 = temp1.map((mItem, mIndex) => ({ ...mItem, key: mIndex + 1 }))
        this.setState({
            dataSource: temp1,
            addRowKey: temp1[temp1.length - 1].key
        })
        // summary
        var index2 = this.props.summaries.findIndex(i => i.random === item.random);
        let temp2 = [...this.props.summaries];
        temp2.splice(index2, 1);
        this.props.deleteSummary(temp2);

        // breakdown
        var index3 = this.props.breakDowns.findIndex(i => i.random === item.random);
        let temp3 = [...this.props.breakDowns];
        temp3.splice(index3, 1);
        this.props.deleteBreakdown(temp3);

        // totals
        var index4 = this.props.totals.findIndex(i => i.random === item.random);
        let temp4 = [...this.props.totals];
        temp4.splice(index4, 1);
        this.props.deleteTotal(temp4);
        
        // result
        var index5 = this.props.results.findIndex(i => i.random === item.random);
        let temp5 = [...this.props.results];
        temp5.splice(index5, 1);
        this.props.deleteResult(temp5);
    }

}

