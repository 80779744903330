import React from 'react';
import { Col, Table, Row, Tooltip } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { changeMoney } from 'utils';
import { ColumnsType } from 'antd/lib/table';
import { SummariesVo, BreakDownsVo } from 'services/calculator';
import { NextButton } from 'utils/theme';

let size = '10px';
interface Step3Props {
    breakDowns: Array<BreakDownsVo>;
    summaries: Array<SummariesVo>;
    totals: Array<{
        totalLogisticsCost: number
    }>;
    showStep: (type: string) => void
}
interface Step3State {
    summarySources: Array<SummariesVo>,
    breakDownSources: Array<BreakDownsVo>,
    totalsData: Array<{ name: string, value: number, key: string, percent: number }>;
    breakDownsData: Array<{ name: string, value: number, key: string, percent: number }>;
    firstWidth?: number
}

export default class Step3 extends React.Component<Step3Props, Step3State> {

    firstEcharts: ReactEcharts | null = {} as ReactEcharts;

    constructor(props: Step3Props) {
        super(props)
        this.state = {
            summarySources: [],
            breakDownSources: [],
            totalsData: [
                { name: 'Product Cost', key: 'a', value: 0, percent: 0 },
                { name: 'GST', key: 'b', value: 0, percent: 0 },
                { name: 'Logistics Cost', key: 'c', value: 0, percent: 0 },
                { name: 'Duty', key: 'd', value: 0, percent: 0 }
            ],
            breakDownsData: [
                { name: 'Freight', key: 'a', value: 0, percent: 0 },
                { name: 'Palletisation', key: 'b', value: 0, percent: 0 },
                { name: 'THC', key: 'c', value: 0, percent: 0 },
                { name: 'Warehouse Delivery', key: 'd', value: 0, percent: 0 },
                { name: 'Clearance', key: 'e', value: 0, percent: 0 },
                { name: 'Handling', key: 'f', value: 0, percent: 0 },
                { name: 'Do/Fee', key: 'g', value: 0, percent: 0 },
                { name: 'Documentation at Destination', key: 'h', value: 0, percent: 0 }
            ],
            firstWidth: 0
        };
    }

    columns: ColumnsType<SummariesVo> = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'indx',
            align: 'center',
            width: '0.5%',
            render: (_text, _record, index) => {
                return index + 1;
            }
        },
        {
            title: () => { return <span>Item,<br />material description</span> },
            dataIndex: 'itemDescription',
            align: 'center',
            width: '10%'
        },
        {
            title: 'Commerical Value',
            dataIndex: 'commericalValue',
            align: 'center',
            width: '4%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'CTN CBM',
            dataIndex: 'ctnCbm',
            align: 'center',
            width: '6%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'Total CTNs',
            dataIndex: 'totalCtns',
            align: 'center',
            width: '5%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'Total CBM',
            dataIndex: 'totalCbm',
            align: 'center',
            width: '6%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'Total Gross Weight KG',
            dataIndex: 'totalGrossWeightKg',
            align: 'center',
            width: '5%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'Total Shipping excl. Duty + Tax',
            dataIndex: 'totalShippingExclDutyPlusTax',
            align: 'center',
            className: 'specialth',
            width: '8%',
            render: (text, record) => {
                var t = `${record.lclCost} + ${record.twentyCost} +  
                                ${record.fortyCost} + ${record.documentation} +
                                ${record.thc} + ${record.warehouseDelivery} +
                                ${record.handling} + ${record.clearance} +
                                ${record.doFee} + ${(record.warehouse === -1 ? 0 : record.warehouse)} + 
                                ${(record.delivery === -1 ? 0 : record.delivery)} + ${record.insurance}`;
                return <Tooltip title={t}>{changeMoney(text)}</Tooltip>

            }
        },
        {
            title: 'Total Duty & Tax Outlay',
            dataIndex: 'totalDutyAndTaxOutlay',
            align: 'center',
            className: 'specialth',
            width: '8%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'Total Shippingcosts incl. Duty + Tax',
            dataIndex: 'totalShippingCostsInclDutyPlusTax',
            align: 'center',
            className: 'specialth',
            width: '10%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'Freight and Duty per piece',
            dataIndex: 'freightAndDutyPerPiece',
            align: 'center',
            className: 'specialth',
            width: '5%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'DDP Price p/piece',
            dataIndex: 'ddpPricePPiece',
            align: 'center',
            className: 'specialth',
            width: '5%',
            render: (text) => {
                return changeMoney(text)
            }
        },
        {
            title: 'Freight + Duty %',
            dataIndex: 'freightPlusDutyPercent',
            align: 'center',
            className: 'specialth',
            width: '6%',
            render: (text) => {
                return ((text * 10000) / 100.0).toFixed(1) + '%'
            }
        }
    ]

    breakdownColumns: ColumnsType<BreakDownsVo> = [
        {
            title: '',
            dataIndex: '',
            key: '',
            align: 'center',
            width: '0.5%',
            className: 'parentCol',
            children: [{
                title: 'No.',
                dataIndex: 'index',
                align: 'center',

                render: (text, record, index) => {
                    return index + 1;
                }
            },
            {
                title: 'Mode',
                dataIndex: 'mode',
                align: 'center',
                width: '2%'
            }]
        },
        {
            title: 'Freight',
            dataIndex: 'itemDescription',
            align: 'center',
            width: '10%',
            className: 'parentCol',
            children: [
                {
                    title: 'LCL Cost',
                    dataIndex: 'lclCost',
                    align: 'center',
                    width: '3%',
                    render: (text) => {
                        return changeMoney(text)
                    }
                },
                {
                    title: 'LCL (CBM)',
                    dataIndex: 'numLcl',
                    key: 'numLcl',
                    align: 'center',
                    width: '3%',
                    render: (text) => {
                        return changeMoney(text)
                    }
                },
                {
                    title: '20ft Freight Cost',
                    dataIndex: 'twentyCost',
                    key: 'twentyCost',
                    align: 'center',
                    render: (text) => {
                        return changeMoney(text)
                    }
                },
                {
                    title: '20ft QTY',
                    dataIndex: 'twentyGpContainer',
                    key: 'twentyGpContainer',
                    align: 'center'
                },
                {
                    title: '40HC Freight Cost',
                    dataIndex: 'fortyCost',
                    key: 'fortyCost',
                    align: 'center',
                    width: '4%',
                    render: (text) => {
                        return changeMoney(text)
                    }
                },
                {
                    title: '40HC QTY',
                    dataIndex: 'fortyGpContainer',
                    key: 'fortyGpContainer',
                    align: 'center',
                    width: '3%'
                }]
        },
        {
            title: 'Destination Charges',
            dataIndex: 'destinationcharges',
            key: 'destinationcharges',
            align: 'center',
            width: '5%',
            className: 'parentCol',
            children: [{
                title: 'Documentation at Destination',
                dataIndex: 'documentation',
                key: 'documentation',
                align: 'center',
                render: (text) => {
                    return changeMoney(text)
                }
            },
            {
                title: 'Destination THC, incl ISPS',
                dataIndex: 'thc',
                key: 'thc',
                align: 'center',
                render: (text) => {
                    return changeMoney(text)
                }
            },
            {
                title: 'Delivery to Consignee',
                dataIndex: 'warehouseDelivery',
                key: 'warehouseDelivery',
                align: 'center',
                width: '4%',
                render: (text) => {
                    return changeMoney(text)
                }
            },
            {
                title: 'Handling Charges',
                dataIndex: 'handling',
                key: 'handling',
                align: 'center',
                width: '6%',
                render: (text) => {
                    return changeMoney(text)
                }
            },
            {
                title: 'Customs Clearance',
                dataIndex: 'clearance',
                key: 'clearance',
                align: 'center',
                width: '6%',
                render: (text) => {
                    return changeMoney(text)
                }
            },
            {
                title: 'D/O Fee',
                dataIndex: 'doFee',
                key: 'doFee',
                align: 'center',
                width: '3%',
                render: (text) => {
                    return changeMoney(text)
                }
            }]

        },
        {
            title: 'OVAS or DVAS if required',
            dataIndex: 'itemDescription',
            key: 'itemDescription',
            align: 'center',
            width: '10%',
            className: 'parentCol',
            children: [{
                title: 'Warehousing Services incl. Palletization if requested',
                dataIndex: 'warehouse',
                key: 'warehouse',
                align: 'center',
                render: (text) => {
                    return text === -1 ? '' : changeMoney(text)
                }
            },
            {
                title: 'Final Delivery to requested destination',
                dataIndex: 'delivery',
                key: 'delivery',
                align: 'center',
                render: (text) => {
                    return text === -1 ? '' : changeMoney(text)
                }
            }]
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            align: 'center',
            children: [{
                title: 'Insurance',
                dataIndex: 'insurance',
                key: 'insurance',
                align: 'center',
                render: (text) => {
                    return changeMoney(text)
                }
            }]
        },
        {
            title: 'TAX',
            dataIndex: 'tax',
            key: 'tax',
            align: 'center',
            width: '10%',
            className: 'parentCol',
            children: [{
                title: 'DUTY %',
                dataIndex: 'dutyPercent',
                key: 'dutyPercent',
                align: 'center',
                width: '4%',
                render: (text) => {
                    return ((text * 10000) / 100.0).toFixed(1) + '%'
                }
            },
            {
                title: 'DUTY',
                dataIndex: 'duty',
                key: 'duty',
                align: 'center',
                width: '3%',
                render: (text) => {
                    return changeMoney(text)
                }
            },
            {
                title: 'VAT/GST %',
                dataIndex: 'gstPercent',
                key: 'gstPercent',
                align: 'center',
                width: '5%',
                render: (text) => {
                    return ((text * 10000) / 100.0).toFixed(1) + '%'
                }
            },
            {
                title: 'VAT if application',
                dataIndex: 'gst',
                key: 'gst',
                align: 'center',
                width: '3%',
                render: (text) => {
                    return changeMoney(text)
                }
            }]
        }
    ]

    getFirstOption() {
        let option = {
            color: ["#786EAF", "#C8D23C", "#32AAE1", "#FAB43C"],
            series: [
                {
                    type: 'pie',
                    radius: [100, 70],
                    overflow: 'break',
                    left: -150,
                    width: 900,
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    label: {
                        alignTo: 'edge',
                        formatter: (params: any) => {
                            return '{' + params.data.key + '|' + params.name + '}\n{' + params.data.key + '|' + changeMoney(params.value) + '}\n{' + params.data.key + '|percent:' + params.percent + '%}'
                        },
                        rich: {
                            a: {
                                color: '#786EAF'
                            },
                            b: {
                                color: '#C8D23C'
                            },
                            c: {
                                color: '#32AAE1'
                            },
                            d: {
                                color: '#FAB43C'
                            }
                        }
                    },
                    labelLine: {
                        length: 50
                    },
                    data: this.state.totalsData
                }
            ]
        }
        return option;
    }

    getSecondOption() {
        let option = {
            color: ["#CC3399", "#CCCCCC", "#32AAE1", "#786EAF", "#FAB43C", "#C8D23C", "#F7DC6F", "#7FB80E"],
            series: [
                {
                    type: 'pie',
                    radius: [100, 70],
                    left: -200,
                    overflow: 'break',
                    width: 1000,
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 1
                    },
                    label: {
                        alignTo: 'edge',
                        position: 'outside',
                        formatter: (params: any) => {
                            return '{' + params.data.key + '|' + params.name + '}\n{' + params.data.key + '|' + changeMoney(params.value) + '}\n{' + params.data.key + '|percent:' + params.percent + '%}'
                        },
                        rich: {
                            a: {
                                color: '#CC3399'
                            },
                            b: {
                                color: '#CCCCCC'
                            },
                            c: {
                                color: '#32AAE1'
                            },
                            d: {
                                color: '#786EAF' 
                            },
                            e: {
                                color: '#FAB43C'
                            },
                            f: {
                                color: '#C8D23C'
                            },
                            g: {
                                color: '#F7DC6F'
                            },
                            h: {
                                color: '#7FB80E'
                            }
                        }
                    },
                    labelLine: {
                        length: 50
                    },
                    data: this.state.breakDownsData
                }
            ]
        }
        return option;
    }

    componentWillReceiveProps(props: Step3Props) {
        var doFee = 0;
        var handling = 0;
        var freight = 0;
        var clearance = 0;
        var thc = 0;
        var palletisation = 0;
        var warehouseDelivery = 0;
        var documentation = 0;
        var productCost = 0;
        var gst = 0;
        var logisticsCost = 0;
        var duty = 0;
        props.breakDowns.forEach(item => {
            handling += item.handling;
            freight += item.freight;
            clearance += item.clearance;
            thc += item.thc;
            palletisation += item.warehouse;
            warehouseDelivery += item.warehouseDelivery;
            duty += item.duty;
            gst += item.gst;
            doFee += item.doFee;
            documentation += item.documentation;
        });

        props.summaries.forEach(item => {
            productCost += item.commericalValue
        });

        props.totals.forEach(item => {
            logisticsCost += item.totalLogisticsCost
        });

        this.setState({
            breakDownsData: this.getPercentValue([
                { name: 'Freight', key: 'a', value: freight },
                { name: 'Palletisation', key: 'b', value: palletisation < 0 ? 0 : palletisation },
                { name: 'THC', key: 'c', value: thc },
                { name: 'Warehouse Delivery', key: 'd', value: warehouseDelivery },
                { name: 'Clearance', key: 'e', value: clearance },
                { name: 'Handling', key: 'f', value: handling },
                { name: 'Do/Fee', key: 'g', value: doFee },
                { name: 'Documentation at Destination', key: 'h', value: documentation }
            ]),
            totalsData: this.getPercentValue([
                { name: 'Product Cost', key: 'a', value: productCost },
                { name: 'GST', key: 'b', value: gst },
                { name: 'Logistics Cost', key: 'c', value: logisticsCost },
                { name: 'Duty', key: 'd', value: duty }
            ]),
            summarySources: props.summaries.map((item, index) => ({ ...item, key: index + 1 })),
            breakDownSources: props.breakDowns.map((item, index) => ({ ...item, key: index + 1 })),
            firstWidth: this.firstEcharts?.getEchartsInstance().getWidth(),
        })
    }

    // The maximum balance method solves the problem that the sum of percentages is not 100%
    getPercentValue(arrList: Array<{ name: string, value: number, key: string, percent?: number }>) {
        let precision = 2;
        let sum = arrList.reduce((acc, val) => {
            return acc + (isNaN(val.value) ? 0 : val.value);
        }, 0);
        if (sum === 0) {
            return arrList.map(item => ({ ...item, percent: 0 }))
        }
        let digits = Math.pow(10, precision);
        let votesPerQuota = arrList.map((val) => {
            return {
                ...val,
                percent: (isNaN(val.value) ? 0 : val.value) / sum * digits * 100
            }
        });
        let targetSeats = digits * 100;
        let seats = votesPerQuota.map((votes) => {
            return {
                ...votes,
                percent: Math.floor(votes.percent)
            }
        });
        let currentSum = seats.reduce((acc, val) => {
            return acc + val.percent;
        }, 0);
        let remainder = votesPerQuota.map(function (votes, index) {
            return votes.percent - seats[index].percent;
        });
        while (currentSum < targetSeats) {
            let max = Number.NEGATIVE_INFINITY;
            let maxId = -1;
            for (let i = 0, len = remainder.length; i < len; ++i) {
                if (remainder[i] > max) {
                    max = remainder[i];
                    maxId = i;
                }
            }
            const maxItem = { ...seats[maxId], percent: seats[maxId].percent + 1 }
            seats[maxId] = maxItem;
            remainder[maxId] = 0;
            ++currentSum;
        }
        const list = seats.map((item, index) => ({ ...item, percent: seats[index].percent / digits }))
        return list
    }

    render() {
        return (
            <div style={{ marginTop: '30px' }}>
                <div style={{ width: '100%', fontSize: size, fontWeight: 600 }}>
                    <section className="code-box-meta markdown">
                        <div className="code-box-title">
                            <div><span style={{ color: '#E60080' }}>Step3: </span> Confrimation</div>
                        </div>
                        <div className="code-box-description">
                            <section className="code-box-meta markdown">
                                <div className="code-box-title">
                                    <div>Summary</div>
                                </div>
                                <div className="code-box-description">
                                    <Table
                                        key="summary"
                                        rowKey="key"
                                        size={'small'}
                                        rowClassName={(record, index) => {
                                            if (index % 2 !== 1) {
                                                return 'striped'
                                            }
                                            return '';
                                        }}
                                        pagination={false}
                                        bordered
                                        columns={this.columns}
                                        dataSource={this.state.summarySources}
                                    />
                                </div>
                            </section>
                            <br></br>
                            <section className="code-box-meta markdown">
                                <div className="code-box-title">
                                    <div>Breakdown</div>
                                </div>
                                <div className="code-box-description">
                                    <Table
                                        size={'small'}
                                        key="Breakdown"
                                        rowKey="key"
                                        rowClassName={(record, index) => {
                                            if (index % 2 !== 1) {
                                                return 'striped'
                                            }
                                            return ''
                                        }}
                                        pagination={false}
                                        bordered
                                        columns={this.breakdownColumns}
                                        dataSource={this.state.breakDownSources}
                                    />
                                    <Row style={{ margin: '10px' }}>
                                        <Col className="gutter-row" md={6} lg={8} >
                                            <ReactEcharts style={{ height: '400px' }} ref={(e) => { this.firstEcharts = e }} option={this.getFirstOption()} />
                                        </Col>
                                        <Col style={{ marginTop: '80px', border: '1px solid #f0f0f0', height: '250px', lineHeight: '50px' }} className="gutter-row" md={6} lg={4}>
                                            <div style={{ textAlign: 'left', padding: '10px' }}>
                                                <Row style={{ color: '#786EAF' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Product Cost: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.totalsData[0].value)}({this.state.totalsData[0].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#FAB43C' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span >Duty: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.totalsData[3].value)}({this.state.totalsData[3].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#32AAE1' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Logistics Cost: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.totalsData[2].value)}({this.state.totalsData[2].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#C8D23C' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>GST: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.totalsData[1].value)}({this.state.totalsData[1].percent}%)</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" md={6} lg={8} >
                                            <ReactEcharts style={{ height: '400px' }} option={this.getSecondOption()} />
                                        </Col>
                                        <Col style={{ marginTop: '80px', border: '1px solid #f0f0f0', height: '400px', lineHeight: '38px', whiteSpace: 'nowrap' }} className="gutter-row" md={6} lg={4}>
                                            <div style={{ textAlign: 'left', padding: '10px' }}>
                                                <Row style={{ color: '#CC3399' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Freight: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[0].value)}({this.state.breakDownsData[0].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#32AAE1' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span >THC: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[2].value)}({this.state.breakDownsData[2].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#FAB43C' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Clearance: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[4].value)}({this.state.breakDownsData[4].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#786EAF' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Warehouse Delivery: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[3].value)}({this.state.breakDownsData[3].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#C8D23C' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Handling: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[5].value)}({this.state.breakDownsData[5].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#CCCCCC' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Palletisation: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[1].value)}({this.state.breakDownsData[1].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#F7DC6F' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Do/Fee: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[6].value)}({this.state.breakDownsData[6].percent}%)</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{ color: '#7FB80E' }}>
                                                    <Col className="gutter-row" span={16}>
                                                        <span>Documentation at <br /> Destination: </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={8}>
                                                        <span>{changeMoney(this.state.breakDownsData[7].value)}({this.state.breakDownsData[7].percent}%)</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ textAlign: 'right' }}>
                                        <Col className="gutter-row" md={6} lg={8}>
                                        </Col>
                                        <Col className="gutter-row" md={6} lg={8}>
                                        </Col>
                                        <Col className="gutter-row" md={6} lg={8}>
                                            <NextButton onClick={() => this.props.showStep('step4')}>NEXT</NextButton>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </div >
        )
    }
}