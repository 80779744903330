import { LayoutProps } from "layout/layout-container-black";
import React from "react";
import { Redirect } from "react-router-dom";
// import NotAllow from "../pages/not-found/403";
import { RouteType } from './router-config'

/** 
 * 根据当前浏览器url找到配置路由信息
 */
export const getCurrentRoute = (array: RouteType[] = [], pathname: string) => {
    let route: RouteType | undefined;
    const seek = (array: RouteType[] = []) => {
        array.forEach((item: RouteType) => {
            if (item.path === pathname) {
                route = item;
            }
            if (pathname.indexOf(item.path) !== -1 && item.route && item.route.length) {
                seek(item.route);
            }
        })
    }
    seek(array)
    return route;
}

export const getRouteList = (array: RouteType[] = []) => {
    let route: RouteType[] = [];
    const seek = (array: RouteType[] = []) => {
        array.forEach((item: RouteType) => {
            if (item.route && item.route.length) {
                seek(item.route);
            } else {
                route.push(item)
            }
        })
    }
    seek(array)
    return route;
}

export const DispatchContext = React.createContext<LayoutProps>({});

/**
 * 判断进入页面的权限
 */
function Authorized(props: any) {
    const {
        children,
        redirectPath,
        // pageClaims,
        // moduleCode
    } = props;
    /** 需要重定向的url：如/要重定向到首页/welcome */
    if (redirectPath) {
        return <Redirect to={redirectPath} />
    }
    /** 有权限 */
    // if (!moduleCode || (pageClaims && pageClaims.indexOf(moduleCode) !== -1)) {
    return <DispatchContext.Provider value={props}>
        {children}
    </DispatchContext.Provider>
    // }
    /** 无权限 */
    // return <NotAllow />;
}

export default Authorized;