import { AGetDetail } from "services/login";
/** 获取权限 */
export const fetchPermissionData = async (dispatch) => {
  dispatch({
    type: 'layoutLoading',
    data: true
  })
  const res = await AGetDetail();
  if (res.success) {
    dispatch({
      type: "updatePermission",
      data: {
        claims: ['p.bsUserList.detail'],
        permissions: ['m.bsUserMg', 'm.bsUserMg.bsUserList', 'm.infraMg', 'm.infraMg.permitPresidentSetup', 'm.infraMg.systemRole'],
        username: res.data.userName,
        tenantName: res.data.tenantName,
        tenantCount: res.data.tenantCount,
        tenantLogo: res.data.tenantLogo
      }
    });
    setTimeout(() => {
      dispatch({
        type: 'layoutLoading',
        data: false
      })
    }, 300)
  } else {
    dispatch({
      type: "updatePermission",
    });
    dispatch({
      type: 'layoutLoading',
      data: false
    })
  }
  return res.success
};

const Setting = (
  state = {
    collapsed: false,
    pageTitle: "",
    breadList: [],
    pageClaims: [],
    btnClaims: [],
    username: "",
    tenantName: "",
    tenantCount: 0,              
    layoutLoading: true
  },
  action
) => {
  switch (action.type) {
    case "layoutLoading":
      return { ...state, layoutLoading: action.data };
    case "menuCollapsed":
      return { ...state, collapsed: action.data };
    case "pageTitle":
      return { ...state, pageTitle: action.data };
    case "breadList":
      return { ...state, breadList: action.data };
    case "updatePermission":
      if (!action.data) {
        window.localStorage.removeItem('userToken');
        window.location.replace(
          `/login?redirect=${encodeURIComponent(window.location.pathname)}`
        );
      }
      return {
        ...state,
        pageClaims: action.data.permissions,
        btnClaims: action.data.claims,
        username: action.data.username,
        tenantName: action.data.tenantName,
        tenantCount: action.data.tenantCount,
        tenantLogo: action.data.tenantLogo 
      };
    default:
      return state;
  }
};

export default Setting;
