import React from 'react';
import { Checkbox, Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ResultVo, SendEmailV2 } from 'services/calculator';
const { confirm } = Modal;

interface Step4Props {
    results: ResultVo[];
}
interface Step4State {
    canSend: string;
    isShow: boolean;
    emailAddress: string;
}
export default class Step4 extends React.Component<Step4Props, Step4State> {
    sendForm = React.createRef();

    constructor(props: Step4Props) {
        super(props)
        this.state = {
            canSend: 'disabled',
            isShow: false,
            emailAddress: ''
        };
    }

    render() {
        return (
            <div style={{ marginTop: '30px' }}>
                <div style={{ width: '100%', fontWeight: 600 }}>
                    <section className="code-box-meta markdown">
                        <div className="code-box-title">
                            <div><span style={{ color: '#E60080' }}>Step4: </span> Please confrim the conditions, and client the SEND button</div>
                        </div>
                        <div className="code-box-description">
                            <section className="code-box-meta markdown" style={{ width: '70%', textAlign: 'left' }}>
                                <div style={{ width: '90%', textAlign: 'left', padding: '10px' }}>
                                    By using these rates are hereby agreeing to the following conditions:<br /><br />
                                    Commodity : General Cargo (Non DG) only, need to provide UN38.3 report and MSDS
                                    Above Rates are subject to additional cost if the document is incomplete or cargo invoice is under declared
                                    Quotes using this template include 0 days of storage. If Warehousing Services are required please specify. <br /><br />
                                    Duty / tax are estimated as non-binding advise. Ligentia reserve the right to charge as per actual outlay.
                                    Container loading/unloading, detention, demurrage, storage, inspection fee, vehicle detention after free hours are excluded
                                    Container amount is calculated based on below rules and carton size, could be adjusted according to actual carton measurement
                                    Ligentia also offers Palletization service at both orign ; destination (as per request)
                                </div>
                            </section>
                            <section style={{ textAlign: 'right' }}>
                                <Checkbox onChange={(value) => this.getChecked(value)}></Checkbox>&nbsp;&nbsp;&nbsp;Agreed with above conditions&nbsp;&nbsp;&nbsp;
                                <Button style={{ width: '200px'}} type={!!this.state.canSend ? "default" : "primary"} className={!!this.state.canSend ? "" : "button gradient purple"} disabled={!!this.state.canSend} onClick={() => this.showConfirm()}>SEND</Button>
                            </section>
                        </div>
                    </section>

                </div>
            </div>
        )
    }

    getChecked(value: CheckboxChangeEvent) {
        var t = value.target.checked;
        this.setState({
            canSend: t ? '' : 'disabled'
        });
    }

    emailAddressChange(value: { target: { value: any; }; }) {
        this.setState({
            emailAddress: value.target.value
        });
    }

    showConfirm() {
        var that = this;
        confirm({
            title: 'Confirm to send the result?',
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                const res = await SendEmailV2({
                    results: that.props.results
                })
                if (res.success) {
                    if (res.code === 200) {
                        message.success('Succeed', 1);
                    } else {
                        message.error('Fail', 1);
                    }
                }
            },
            okText: "SEND",
            cancelText: "CANCEL"
        });
    }
}

