import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";
import "moment/locale/zh-cn";
import "./index.less";
import App from "./App.js";

ReactDOM.render(
  <ConfigProvider>
    <Router>
      <App />
    </Router>
  </ConfigProvider>,
  document.getElementById("root")
);
