import type { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';

/** 七牛url */
export const QINIUPATH = 'https://apkj-static.apyfc.com';

/**
 * 约束default不能修改
 */
interface IDateFormatEnum {
    default: 'YYYY-MM-DD HH:mm:ss',
    date: 'YYYY-MM-DD',
    rangeDate: 'YYYY.MM.DD',
    rangeMinute: 'YYYYMMDD HH:mm',
    minuteDate: 'YYYY-MM-DD HH:mm',
    none: 'YYYYMMDD'
}

/** 
 * 时间戳格式化格式 
*/
export const DateFormatEnum: IDateFormatEnum = {
    default: 'YYYY-MM-DD HH:mm:ss',
    date: 'YYYY-MM-DD',
    rangeDate: 'YYYY.MM.DD',
    rangeMinute: 'YYYYMMDD HH:mm',
    minuteDate: 'YYYY-MM-DD HH:mm',
    none: 'YYYYMMDD'
};

/**
 * form表单的配置
 */
export const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

/**
 * form表单按钮
 */
export const tailFormItemLayout = {
    wrapperCol: {
        span: 18,
        offset: 6,
    },
};

/** 上传文件的类型 */
export type UploadFileType = UploadFile<{ hash: string; key: string }>;

/** 获取文件类型 */
export function getFileTypeByName(url: string) {
    const stuffix = url.substr(url.lastIndexOf('.'), url.length);
    const fileTypeList: { [key in string]: string } = {
        '.png': 'image/png',
        '.jpg': 'image/jpg',
        '.jpeg': 'image/jpeg',
        '.doc': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.docx': 'application/msword',
        '.pdf': 'application/pdf',
        '.xls': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '.xlsx': 'application/vnd.ms-excel',
        '.mp4': 'video/mp4',
    };
    return fileTypeList[stuffix];
}

/**
 * 获取文件后缀名
 */
export function getFileExt(type: string) {
    const fileExtList: { [key in string]: string } = {
        'image/png': '.png',
        'image/jpg': '.jpg',
        'image/jpeg': '.jpeg',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.doc',
        'application/msword': '.docx',
        'application/pdf': '.pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xls',
        'application/vnd.ms-excel': '.xlsx',
        'video/mp4': '.mp4',
    };
    return fileExtList[type];
}

/** 
 * 格式化url参数
 * urlString url?后面的参数
*/
export function GetUrlParams(params: { key: string, urlString?: string }) {
    const url = params.urlString ? params.urlString.replace('?', '') : window.location.search.replace('?', '');
    const urlArr = url.split('&');
    const urlParams: { [key in string]: string } = {};
    urlArr.forEach(item => {
        const itemArr = item.split('=');
        urlParams[`${itemArr[0]}`] = itemArr[1]
    })
    return urlParams[params.key]
}

/**
 * 日期格式化
 * @param date 要格式化的日期
 * @param type 是展示还是表单
 * @param str 格式化字符串
 */
export function dateFormat(date?: number, type?: 1 | 2, str?: string) {
    let dStr = 'YYYY-MM-DD HH:mm:ss';
    if (str) dStr = str;
    if (date) return moment(date * 1000).format(dStr);
    return type === 1 ? '无' : '-';
}


/** 商户后台权限 */
declare const MerchantTypes: ['p.bsUserList.detail', 'p.permitPresidentSetup.add', 'p.systemRole.setRight', 'p.systemRole.modify', 'p.systemRole.add', 'p.bsUserList.exportxls'];
export declare type PBtnType = typeof MerchantTypes[number];

function findMax(arr: number[]) {
    let max = arr[0];
    arr.forEach(item => {
        if (max < item) {
            max = item;
        }
    })
    return max;
}

export function getNode(data: Api.RoleComponent.RightVo) {
    let result = 1; // 运行结果
    const listNum: number[] = []
    function getTreeItem(sData: Api.RoleComponent.RightVo) {
        if (sData.children && sData.children.length > 0) {
            sData.children.map(gData => {
                listNum.push(gData.key ? gData.key.split('-').length : 0);
                if (gData.children && gData.children.length) {
                    return getTreeItem(gData);
                }
                return null;
            })
        }
    };
    getTreeItem(data);
    result = findMax(listNum)
    return result;
}

export function findParent(type: string, tree: Api.RoleComponent.RightVo[]) {
    let result: Api.RoleComponent.RightVo | undefined; // 运行结果
    function getTreeItem(sData: Api.RoleComponent.RightVo[], parentNode?: Api.RoleComponent.RightVo) {
        if (sData) {
            sData.map(item => {
                if (item.type === type) {
                    result = parentNode;
                }
                if (item.children && item.children.length > 0) {
                    return getTreeItem(item.children, item)
                }
                return null;
            })
        }
    };
    getTreeItem(tree);
    return result;
}

function changeMoney(money: string | number){
    if (isNaN(Number(money))) {
        console.log(money)
    }
    const s = Number(money).toLocaleString('zh-CN', {
        style: 'currency',
        currency: 'CNY',
    })
    return s.replace(/¥/g, '')
}

// var arr = [1, 11111, 2121212122222112, 1.22222, 1.3, 1.0, 1.0, 111111111.0, 122222.0, 1.0333, 1.02, 1222222.213123, 12333333.1, 12222333.42]

// arr.forEach(item => {
//     console.log(item, changeMoney(item))
// })


function changeNumber(val: string){
    if (val) {
        let a = (val + "").replace(/,/g,"");
        return Number(a)
    }else {
        return 0
    }
}

const inputNumFormatterkilo = {
    parser: function(value: string | undefined) {
        return value !== undefined ? changeNumber(value) : 0;
    },
    formatter: function(value: number | undefined, info: {
        userTyping: boolean;
        input: string;
    }): string {
        if (info.userTyping) {
            return value ? value.toString() : ''
        } else {
            return value ? `${changeMoney(value)}` : '';
        }
    }
}

export {
    inputNumFormatterkilo,
    changeMoney,
    changeNumber
}