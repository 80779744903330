import Welcome from "pages/calculator";
import NotFound from "pages/not-found/404";
import NotAllow from "pages/not-found/403";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";

export interface RouteType {
  exact?: boolean;
  path: string;
  redirectPath?: string;
  name?: string;
  icon?: string;
  component?:
  | React.ComponentType<any>
  | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
  | undefined;
  moduleCode?: string;
  route?: Array<RouteType>;
  breadList?: Array<string>;
  hidden?: boolean
}

const routes: Array<RouteType> = [
  {
    exact: true,
    path: "/",
    redirectPath: "/welcome",
  },
  {
    path: "/welcome",
    name: "Home",
    icon: "HomeOutlined",
    component: Welcome,
    breadList: ["Home"],
  },
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "/403",
    component: NotAllow,
  },
];

export default routes;
