import { Button } from "antd";
import { NativeButtonProps } from "antd/lib/button/button";
import './theme.less'
import React from "react";

export class NextButton extends React.Component<NativeButtonProps> {
  render() {
      return <Button className="button gradient blue" {...this.props}>{this.props.children}</Button>
  }
}

export class SaveButton extends React.Component<NativeButtonProps> {
  render() {
      return <Button className="SaveButton" {...this.props}>{this.props.children}</Button>
  }
}
