import { message } from "antd";

//检查请求状态
function checkStatus(response: Response, url: string) {
    if (response.status >= 200 && response.status < 500) {
        if (response.status === 401) {
            message.destroy();
            message.error('登录过期，请重新登录');
            window.localStorage.removeItem('userToken');
            window.location.replace(`/login?redirect=${encodeURIComponent(window.location.pathname)}`);
        }
        if (response.status === 403) {
            message.destroy();
            message.error(`你没有权限访问${url}接口`);
        }
        return response
    }
    const error = new Error(response.statusText)
    throw error
}

/** 格式化get请求参数 */
export const ParseParams = (params: any) => {
    if (params) {
        let paramsArray: Array<string> = [];
        Object.keys(params).forEach(key => {
            if (params[key] !== undefined) {
                paramsArray.push(key + '=' + params[key])
            }
        })
        return `?${paramsArray.join('&')}`
    }
    return ''
}

const request = <a>(url: string, options: any): Promise<a> => {
    const requestMethod = options.method === undefined || (options.method).toUpperCase() === 'GET' ? 'GET' : options.method;
    const requestUrl = requestMethod === 'GET' && options.params ? `${url}${ParseParams(options.params)}` : url;
    const token = window.localStorage.getItem('userToken') as string;
    return fetch(requestUrl, {
        method: requestMethod,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'authorization': token
        },
        body: requestMethod === 'GET' ? undefined : JSON.stringify({ ...options.data })
    }).then((response) => checkStatus(response, url))
        .then((res) => {
            return res.json()
        })
        .catch(err => {
            return err
        })
}

export default request;